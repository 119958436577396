import { useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import { MAX_SEARCH_PRODUCTS } from '@/core/constants/products';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { sendSearchQuerySetEvent } from '@/modules/analytics/eventHandlers/sendSearchQuerySetEvent';
import { sendSearchResultsEvent } from '@/modules/analytics/eventHandlers/sendSearchResultsEvent';

import {
  ProductSearchQuery,
  useProductSearchQuery,
} from '../../../../../../product/queries/ProductSearch.delio.generated';

export const useDebouncedProductSearchQuery = ({
  searchValue,
  productsLimit = MAX_SEARCH_PRODUCTS,
}: {
  searchValue: string;
  productsLimit?: number;
}) => {
  const [debouncedInputValue] = useDebounce(
    searchValue,
    inputValueDebounceTimeMs
  );

  const { coordinates, loading } = useUserAddressCoordinates();

  const isInputValueLongEnough =
    debouncedInputValue.length >= minimumInputValueLength;

  const productSearchQueryOptions = useMemo(
    () => ({
      skip: loading || !isInputValueLongEnough,
      onCompleted: (data: ProductSearchQuery) => {
        sendSearchQuerySetEvent({
          query: debouncedInputValue,
          sku: data.productSearch.results.map((product) => product.sku),
          attributionToken: data.productSearch.attributionToken ?? undefined,
        });
        sendSearchResultsEvent({
          searchTerm: debouncedInputValue,
          items: data.productSearch.results,
        });
      },
      variables: {
        query: debouncedInputValue,
        limit: productsLimit,
        offset: 0,
        coordinates,
      },
    }),
    [
      loading,
      isInputValueLongEnough,
      debouncedInputValue,
      coordinates,
      productsLimit,
    ]
  );

  const {
    loading: isLoading,
    data,
    error,
  } = useProductSearchQuery(productSearchQueryOptions);

  const products = data?.productSearch.results;
  const attributionToken = data?.productSearch?.attributionToken;

  return {
    total: data?.productSearch?.total,
    attributionToken,
    products: products?.filter(Boolean),
    isErrored: Boolean(error),
    isLoading,
    isInputValueLongEnough,
  };
};

const inputValueDebounceTimeMs = 300;
const minimumInputValueLength = 3;
