import { RootState } from '../store.types';

export const selectCartProgressIndicatorState = (state: RootState) =>
  state.cartProgressIndicator;

export const selectCartProgressIndicatorIsOpen = (state: RootState) =>
  state.cartProgressIndicator.isOpen;

export const selectCartProgressIndicatorFreeDeliveryMessageVisible = (
  state: RootState
) => state.cartProgressIndicator.freeDeliveryMessageVisible;

export const selectCartProgressIndicatorVisibleRewardDescription = (
  state: RootState
) => state.cartProgressIndicator.visibleRewardDescription;

export const selectCartProgressIndicatorIsConfettiAnimationVisible = (
  state: RootState
) => state.cartProgressIndicator.isConfettiAnimationVisible;
