import { RefObject, useEffect } from 'react';

export const useBlurOnScroll = ({ ref }: { ref: RefObject<HTMLElement> }) => {
  useEffect(() => {
    if (!ref.current) return;
    const currentMenuRef = ref.current;
    const isTextInput = (node: HTMLElement): boolean =>
      ['INPUT', 'TEXTAREA'].includes(node.nodeName);

    const handleScroll = (): void => {
      const activeElement = document.activeElement as HTMLElement | null;

      if (activeElement && isTextInput(activeElement)) {
        activeElement.blur();
      }
    };

    currentMenuRef.addEventListener('scroll', handleScroll, false);

    // Cleanup listener on component unmount
    return () => {
      currentMenuRef.removeEventListener('scroll', handleScroll, false);
    };
  }, [ref]);
};
