import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useHideChat } from '@/core/hooks/useHideChat';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { useDebouncedProductSearchQuery } from '@/modules/layout/components/MainHorizontalNav/components/ProductSearch/hooks/useDebouncedProductSearchQuery';
import { useSearchDefaultsQuery } from '@/modules/layout/queries/SearchDefaultsQuery/SearchDefaults.delio.generated';

export const useProductSearch = (props?: { productsLimit: number }) => {
  const { push, events, query } = useRouter();

  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(
    typeof query.q === 'string' ? query.q : ''
  );
  const { coordinates, loading: isCoordinatesLoading } =
    useUserAddressCoordinates();
  const { data, loading: isSearchDefaultsLoading } = useSearchDefaultsQuery({
    variables: { coordinates },
    skip: isCoordinatesLoading || !isSearchOpen,
  });

  const searchDefaults = data?.searchDefaults;

  const debouncedProductSearch = useDebouncedProductSearchQuery({
    searchValue,
    productsLimit: props?.productsLimit,
  });
  const total = debouncedProductSearch?.total;
  const hasMoreSuggestedProducts =
    Number(total) > (props?.productsLimit ?? MAX_PRODUCTS);

  const suggestedProducts =
    debouncedProductSearch.products ??
    (props?.productsLimit
      ? searchDefaults?.products.slice(0, props.productsLimit)
      : searchDefaults?.products);

  const redirectToSearchPage = async () => {
    await push(
      createUrl(searchValue, '/search', debouncedProductSearch.attributionToken)
    );
  };

  const createProductUrl = (productSlug: string) => {
    const href = ['/products', productSlug].filter(Boolean).join('/');

    return createUrl(
      searchValue,
      href,
      debouncedProductSearch.attributionToken
    );
  };

  const isSuggestionsLoading = isCoordinatesLoading || isSearchDefaultsLoading;

  const isSearchProductsLoading = debouncedProductSearch.isLoading;

  const isEmpty =
    Array.isArray(suggestedProducts) &&
    !suggestedProducts.length &&
    !isSuggestionsLoading;

  useEffect(() => {
    const lockScroll = () => {
      document.body.style.overflow = 'hidden'; // Disable body scrolling
      document.body.style.position = 'fixed'; // Fix body position
      document.body.style.width = '100%'; // Prevent resizing issues
    };

    const unlockScroll = () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
    };

    if (isSearchOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => {
      unlockScroll();
    };
  }, [isSearchOpen]);

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      setSearchOpen(false);
    });
  }, [events]);

  useHideChat(isSearchOpen);

  return {
    isEmpty,
    isSearchOpen,
    redirectToSearchPage,
    searchValue,
    setSearchOpen,
    setSearchValue,
    isSuggestionsLoading,
    totalItems: total,
    phrasesTitle: searchDefaults?.queriesTitle,
    phrasesValues: searchDefaults?.queries,
    brandsTitle: searchDefaults?.brandsTitle,
    brandsValues: searchDefaults?.brands,
    combinedQueriesTitle: searchDefaults?.combinedQueriesTitle,
    searchDefaultsSectionTitle: searchDefaults?.productsTitle,
    suggestedProducts,
    createProductUrl,
    suggestedProductsAttributionToken: debouncedProductSearch.attributionToken,
    hasMoreSuggestedProducts,
    isSearchProductsLoading,
  };
};

const MAX_PRODUCTS = 15;

const createUrl = (
  query: string,
  href: string,
  attributionToken?: string | null
) => {
  if (attributionToken && query) {
    return `${href}?q=${encodeURI(query)}&attributionToken=${attributionToken}`;
  }

  if (attributionToken) {
    return `${href}?attributionToken=${attributionToken}`;
  }

  if (query) {
    return `${href}?q=${encodeURI(query)}`;
  }

  return href;
};
