import dynamic from 'next/dynamic';
import { Trans, useTranslation } from 'next-i18next';
import { Suspense, useRef } from 'react';

import { cn } from '@/core/ui/utils';

import { useProductSearch } from '../../hooks/useProductSearch';
import { SearchField } from '../SearchField/SearchField';

import { SearchSkeleton } from './components/SearchSkeleton/SearchSkeleton';
import { useBlurOnScroll } from './hooks/useBlurOnScroll';

const SearchProductTile = dynamic(
  async () =>
    (await import('./components/SearchProductTile/SearchProductTile'))
      .SearchProductTile,
  {
    ssr: false,
  }
);
const SearchMenuPills = dynamic(
  async () =>
    (await import('./components/SearchMenuPills/SearchMenuPills'))
      .SearchMenuPills,
  {
    ssr: false,
    loading: () => <div className="h-[113px] w-full" />,
  }
);

const SearchBackButton = dynamic(
  async () =>
    (await import('./components/SearchBackButton/SearchBackButton'))
      .SearchBackButton,
  { loading: () => <div className="size-12" />, ssr: false }
);

const SearchEmptyState = dynamic(
  async () =>
    (await import('../SearchEmptyState/SearchEmptyState')).SearchEmptyState,
  { ssr: false }
);

export const MobileSearch = () => {
  const { t } = useTranslation('product');

  const {
    isSearchOpen,
    redirectToSearchPage,
    searchValue,
    setSearchOpen,
    setSearchValue,
    isSuggestionsLoading,
    isEmpty,
    totalItems,
    combinedQueriesTitle,
    brandsValues,
    phrasesValues,
    searchDefaultsSectionTitle,
    suggestedProductsAttributionToken,
    createProductUrl,
    hasMoreSuggestedProducts,
    isSearchProductsLoading,
    suggestedProducts,
  } = useProductSearch();

  const isSuggestionsOrSearchProductsLoading =
    isSuggestionsLoading || isSearchProductsLoading;

  const pills =
    phrasesValues && brandsValues ? [...phrasesValues, ...brandsValues] : [];

  const menuRef = useRef<HTMLDivElement>(null);

  // Close keyboard on scroll
  useBlurOnScroll({ ref: menuRef });

  return (
    <div
      className={cn(
        isSearchOpen ? 'fixed inset-0 flex h-dvh flex-col bg-white' : 'flex-1'
      )}
    >
      <div
        className={cn(
          'flex items-center',
          isSearchOpen && 'border-b border-border-subtle py-3 pr-4'
        )}
      >
        {isSearchOpen && (
          <SearchBackButton onClick={() => setSearchOpen(false)} />
        )}
        <SearchField
          isSearchOpen={isSearchOpen}
          onClearClick={() => setSearchValue('')}
          onSubmitClick={redirectToSearchPage}
          showClearButton={!!searchValue}
          showLoader={isSuggestionsOrSearchProductsLoading}
          onClick={() => setSearchOpen(true)}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </div>
      <div className="overflow-auto" ref={menuRef}>
        {isSearchOpen && (
          <>
            {isSuggestionsOrSearchProductsLoading && <SearchSkeleton />}
            {!isSuggestionsOrSearchProductsLoading && isEmpty && (
              <SearchEmptyState query={searchValue} />
            )}
            {!isSuggestionsOrSearchProductsLoading && !isEmpty && (
              <Suspense fallback={<SearchSkeleton />}>
                {combinedQueriesTitle && !totalItems && (
                  <div>
                    <SearchMenuPills
                      onClick={setSearchValue}
                      pills={pills}
                      title={combinedQueriesTitle}
                    />
                  </div>
                )}
                <div className="mb-2 ml-4 mt-6 text-body-lg font-medium text-text-default">
                  {totalItems ? (
                    <Trans
                      ns="product"
                      i18nKey="Found products <Gray>({{total}})</Gray>"
                      components={{
                        Gray: <span className="font-medium text-text-subtle" />,
                      }}
                      values={{ total: totalItems }}
                    />
                  ) : (
                    searchDefaultsSectionTitle
                  )}
                </div>

                <ul className="divide-y divide-border-subtle">
                  {suggestedProducts?.map((item) => (
                    <SearchProductTile
                      key={item.id}
                      product={item}
                      productUrl={createProductUrl(item.slug)}
                      attributionToken={
                        suggestedProductsAttributionToken ?? undefined
                      }
                    />
                  ))}
                </ul>
                {hasMoreSuggestedProducts && (
                  <button
                    type="button"
                    onClick={redirectToSearchPage}
                    className="w-full py-6 text-center text-body-md font-medium text-interactive-default"
                  >
                    {t('See all products')}
                  </button>
                )}
              </Suspense>
            )}
          </>
        )}
      </div>
    </div>
  );
};
