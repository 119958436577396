import { X } from '@phosphor-icons/react';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';

type ProductSearchFieldClearButtonProps = {
  onClick: () => void;
};

export const ProductSearchFieldClearButton = ({
  onClick,
}: ProductSearchFieldClearButtonProps) => (
  <button
    onClick={onClick}
    type="button"
    className="flex size-10 items-center justify-center"
    data-testid="search-clear-button"
  >
    <X size={DEFAULT_ICON_SIZES.md} />
  </button>
);
