import { useTranslation } from 'next-i18next';
import { ChangeEvent, KeyboardEvent, useCallback } from 'react';

import { ProductSearchFieldClearButton } from './components/ProductSearchFieldClearButton/ProductSearchFieldClearButton';
import { ProductSearchFieldInput } from './components/ProductSearchFieldInput/ProductSearchFieldInput';
import { ProductSearchFieldLoader } from './components/ProductSearchFieldLoader/ProductSearchFieldLoader';
import { ProductSearchFieldSearchButton } from './components/ProductSearchFieldSearchButton/ProductSearchFieldSearchButton';

export const SearchField = ({
  showLoader,
  showClearButton,
  onClearClick,
  onSubmitClick,
  isSearchOpen,
  onClick,
  setSearchValue,
  searchValue,
}: {
  showLoader: boolean;
  showClearButton: boolean;
  onClearClick: () => void;
  onSubmitClick: () => void;
  isSearchOpen: boolean;
  onClick: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
}) => {
  const { t } = useTranslation('product');

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmitClick();
      }
    },
    [onSubmitClick]
  );

  return (
    <label className="grow">
      <span className="sr-only">{t('Search products')}</span>

      <div className="relative flex items-center">
        <ProductSearchFieldInput
          placeholder={t('Search products')}
          className="pr-32 md:pr-52"
          onClick={onClick}
          onChange={onChange}
          value={searchValue}
          onKeyDown={onKeyDown}
        />
        <div className="absolute right-1 top-1 flex items-center">
          {showLoader && (
            <div className="mr-1.5">
              <ProductSearchFieldLoader />
            </div>
          )}

          {showClearButton && (
            <div className="mr-2">
              <ProductSearchFieldClearButton onClick={onClearClick} />
            </div>
          )}
          <div>
            <ProductSearchFieldSearchButton
              onClick={onSubmitClick}
              isOpen={isSearchOpen}
            />
          </div>
        </div>
      </div>
    </label>
  );
};
