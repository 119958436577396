import { Skeleton } from '@/core/ui/components/Skeleton/Skeleton';

export const SearchSkeleton = () => (
  <div className="mt-[25px] px-3">
    <Skeleton className="h-5 w-[199px]" />
    <div className="relative mt-7 flex w-full">
      <Skeleton className="mr-3 size-20 min-w-20" />
      <div className="w-full space-y-1">
        <Skeleton className="h-5 w-[199px]" />
        <Skeleton className="h-4 w-[114px]" />
        <Skeleton className="h-4 w-[34px]" />
        <div className="flex justify-end">
          <Skeleton className="h-4 w-[86px]" />
        </div>
      </div>
      <Skeleton className="absolute right-0 top-[-3px] size-9 rounded-full" />
    </div>
  </div>
);
