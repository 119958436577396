import { MutableRefObject, useEffect } from 'react';

export const useOutSideClick = ({
  refs,
  onOutsideClick,
}: {
  refs: Array<MutableRefObject<HTMLElement | null>>;
  onOutsideClick: () => void;
}) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutsideAllRefs = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      );

      if (isOutsideAllRefs) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, refs]);
};
