import { Skeleton } from '@/core/ui/components/Skeleton/Skeleton';

export const BrandsOrPhrasesSkeleton = () => (
  <div className="mx-4 w-[308px] space-y-2">
    <Skeleton className="mb-4 h-5 w-52" />
    <Skeleton className="h-8 w-24" />
    <Skeleton className="h-8 w-24" />
    <Skeleton className="h-8 w-24" />
    <Skeleton className="h-8 w-24" />
  </div>
);
