import { useTranslation } from 'next-i18next';

import { SpinnerIcon } from '@lib/theme/components/SpinnerIcon';

export const ProductSearchFieldLoader = () => {
  const { t } = useTranslation('product');

  return (
    <SpinnerIcon className="size-5" title={t('Loading search results...')} />
  );
};
