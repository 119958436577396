import { Skeleton } from '@/core/ui/components/Skeleton/Skeleton';

export const SearchSkeleton = () => (
  <div>
    <Skeleton className="mb-5 h-5 w-[174px]" />
    <div className="space-y-3">
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </div>

    <div className="mt-6 flex w-full items-center justify-center">
      <Skeleton className="h-5 w-[182px]" />
    </div>
  </div>
);

const ItemSkeleton = () => (
  <div className="flex w-full justify-between rounded-xl px-4 py-5 shadow-border-inset shadow-border-subtle">
    <div className="flex">
      <Skeleton className="mr-3 size-12" />
      <div className="flex w-[231px] flex-col gap-2">
        <Skeleton className="h-5 w-full" />
        <Skeleton className="h-5 w-full" />
      </div>
    </div>

    <div className="flex h-full items-center justify-center self-center">
      <Skeleton className="h-5 w-[54px]" />
    </div>
  </div>
);
