import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useContextSelector } from 'use-context-selector';

import { NavBarBoxContext } from '@/modules/layout/contexts/NavBarBoxContext';
import { useOutSideClick } from '@lib/theme/hooks/useOutSideClick';

import { useProductSearch } from '../../hooks/useProductSearch';
import { SearchField } from '../SearchField/SearchField';

import { BrandsOrPhrasesSkeleton } from './components/BrandsOrPhrasesSkeleton/BrandsOrPhrasesSkeleton';
import { ProductSearchMenuHeader } from './components/ProductSearchMenuHeader/ProductSearchMenuHeader';
import { SearchSkeleton } from './components/SearchSkeleton/SearchSkeleton';

const SearchEmptyState = dynamic(
  async () =>
    (await import('../SearchEmptyState/SearchEmptyState')).SearchEmptyState,
  { ssr: false }
);

const PopularBrands = dynamic(
  async () =>
    (await import('./components/PopularBrands/PopularBrands')).PopularBrands,
  {
    ssr: false,
  }
);

const PopularPhrases = dynamic(
  async () =>
    (await import('./components/PopularPhrases/PopularPhrases')).PopularPhrases,
  {
    ssr: false,
  }
);

const SearchProductTile = dynamic(
  async () =>
    (await import('./components/SearchProductTile/SearchProductTile'))
      .SearchProductTile,
  {
    ssr: false,
  }
);

export const DesktopSearch = ({
  onOpenChange,
}: {
  onOpenChange: (value: boolean) => void;
}) => {
  const { t } = useTranslation('product');

  const {
    isSearchOpen,
    redirectToSearchPage,
    searchValue,
    setSearchOpen,
    setSearchValue,
    isEmpty,
    totalItems,
    brandsTitle,
    brandsValues,
    phrasesTitle,
    phrasesValues,
    searchDefaultsSectionTitle,
    suggestedProducts,
    suggestedProductsAttributionToken,
    createProductUrl,
    hasMoreSuggestedProducts,
    isSuggestionsLoading,
    isSearchProductsLoading,
  } = useProductSearch({ productsLimit: DESKTOP_SEARCH_MAX_ITEMS });

  const containerRef = useRef<HTMLDivElement>(null);

  useOutSideClick({
    refs: [containerRef],
    onOutsideClick: () => setSearchOpen(false),
  });

  useEffect(() => {
    onOpenChange(isSearchOpen);
  }, [isSearchOpen, onOpenChange]);

  const isSuggestionsOrSearchProductsLoading =
    isSuggestionsLoading || isSearchProductsLoading;

  const showEmptyInfo = !isSuggestionsOrSearchProductsLoading && isEmpty;

  const { navBarBottomPosition } = useContextSelector(
    NavBarBoxContext,
    ({ navBarBottomPosition }) => ({
      navBarBottomPosition,
    })
  );

  return (
    <>
      {isSearchOpen && (
        <div
          style={{ top: navBarBottomPosition }}
          className="fixed inset-x-0 bottom-0 bg-custom-modalBackdrop"
        />
      )}
      <div ref={containerRef} className="relative w-full">
        {isSearchOpen && (
          <div className="absolute -left-8 -top-5 h-[88px] w-8 bg-gradient-to-l from-white" />
        )}

        <SearchField
          isSearchOpen={isSearchOpen}
          onClearClick={() => setSearchValue('')}
          onSubmitClick={redirectToSearchPage}
          showClearButton={!!searchValue}
          showLoader={isSuggestionsLoading}
          onClick={() => setSearchOpen(true)}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {showEmptyInfo && (
          <div className="rounded-b-xl bg-white py-6">
            <SearchEmptyState query={searchValue} />
          </div>
        )}
        {isSearchOpen && !showEmptyInfo && (
          <div
            className="absolute -left-2 mt-0.5 grid grid-cols-[max-content_1fr] rounded-b-xl bg-white py-6"
            // menu has to be wider than input field 8px on the right and left
            style={{ width: 'calc(100% + 16px)' }}
          >
            <div className="max-w-[308px] space-y-5 pl-2 pr-4">
              {isSuggestionsLoading ? (
                <BrandsOrPhrasesSkeleton />
              ) : (
                <>
                  {phrasesTitle && phrasesValues && (
                    <PopularPhrases
                      headerTitle={phrasesTitle}
                      queries={phrasesValues}
                      onPhraseClick={setSearchValue}
                    />
                  )}
                  {brandsTitle && brandsValues && (
                    <PopularBrands
                      headerTitle={brandsTitle}
                      brands={brandsValues}
                      onBrandClick={setSearchValue}
                    />
                  )}
                </>
              )}
            </div>
            <div className="flex-1 pl-4 pr-6">
              {isSuggestionsOrSearchProductsLoading ? (
                <SearchSkeleton />
              ) : (
                <>
                  {searchDefaultsSectionTitle && (
                    <ProductSearchMenuHeader
                      productsTitle={searchDefaultsSectionTitle}
                      total={totalItems}
                    />
                  )}
                  {suggestedProducts && (
                    <ul className="mt-5 flex flex-col gap-3">
                      {suggestedProducts.map((product) => (
                        <SearchProductTile
                          key={product.key}
                          product={product}
                          productUrl={createProductUrl(product.slug)}
                          attributionToken={
                            suggestedProductsAttributionToken ?? undefined
                          }
                        />
                      ))}
                    </ul>
                  )}
                  {hasMoreSuggestedProducts && (
                    <button
                      type="button"
                      onClick={redirectToSearchPage}
                      className="mt-6 w-full text-center text-body-md font-medium text-text-default hover:text-interactive-default"
                    >
                      {t('See all products')}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const DESKTOP_SEARCH_MAX_ITEMS = 4;
