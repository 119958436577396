import { MagnifyingGlass } from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { Button } from '@/core/ui/components/Button/Button';

type ProductSearchFieldSearchButtonProps = {
  onClick?: () => void;
  isOpen?: boolean;
};

export const ProductSearchFieldSearchButton = ({
  onClick,
  isOpen,
}: ProductSearchFieldSearchButtonProps) => {
  const { t } = useTranslation('product');
  return (
    <>
      <Button
        data-testid="search-button"
        onClick={onClick}
        icon={<MagnifyingGlass size={DEFAULT_ICON_SIZES.md} />}
        className="block md:hidden"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClick?.();
          }
        }}
      />
      <Button
        data-testid="search-button"
        onClick={onClick}
        leftIcon={isOpen && <MagnifyingGlass size={DEFAULT_ICON_SIZES.md} />}
        icon={!isOpen && <MagnifyingGlass size={DEFAULT_ICON_SIZES.md} />}
        className="hidden md:block"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClick?.();
          }
        }}
      >
        {isOpen && t('Search')}
      </Button>
    </>
  );
};
