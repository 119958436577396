import { AnnouncementBar } from './AnnouncementBar/AnnouncementBar';
import { MainHorizontalNav } from './MainHorizontalNav/MainHorizontalNav';
import { PromotionNavbar } from './PromotionNavbar';
import { SecondaryHorizontalNav } from './SecondaryHorizontalNav';

export const NavBar = () => (
  <>
    <div className="hidden lg:contents">
      <AnnouncementBar />
    </div>
    <MainHorizontalNav />
    <SecondaryHorizontalNav />
    <div className="hidden lg:contents">
      <PromotionNavbar />
    </div>
  </>
);
